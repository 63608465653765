import { useContext } from 'react';

import { RaffleContext } from '../contexts/RaffleContext';

const useRaffle = () => {
  const stakeContext = useContext(RaffleContext);

  if (stakeContext === null) {
    throw new Error('No parent <StakeContextProvider />');
  }

  return stakeContext.data;
};

export default useRaffle;
