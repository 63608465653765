export const pause = (multiplier) => new Promise(resolve => setTimeout(resolve, (multiplier || 1) * 1000));

export const chainIdToNetworkName = {
  137: 'matic',
  42: 'kovan',
};

export const chainIdToNetworkTitle = {
  137: 'Polygon',
  42: 'Kovan',
};

export const chainIdToInfuraPrefix = {
  137: 'polygon-mainnet',
  42: 'kovan',
};

export const isMainnet = +process.env.REACT_APP_CHAIN_ID === 137;

export const formatNumber = (value, maxDigits = 2) => new Intl.NumberFormat('en-US', { maximumFractionDigits: maxDigits }).format(value);

export const isCaseInsensitiveMatch = (a, b) => {
  if (a === b) return true;
  if (!a || !b) return false;
  return a.toLowerCase() === b.toLowerCase();
};
