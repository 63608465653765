import { useContext, useMemo, createContext, useCallback, useState, useEffect } from 'react';
import Onboard from 'bnc-onboard';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

// constants
import { STORAGE_KEY } from '../constants/storageConstants';

// utils
import { getItem, setItem } from '../utils/storage';
import { chainIdToNetworkName } from '../utils/common';
import { checkIfEtherspotAccount } from '../services/contract';
import { checkIfEmailAdded, submitEmail } from '../services/email';


const WalletService = (onAddress, onProvider, onNetwork, chainId = +process.env.REACT_APP_CHAIN_ID) => Onboard({
  walletSelect: {
    wallets: [
      {
        walletName: "metamask",
        preferred: true,
      },
      {
        walletName: "walletConnect",
        preferred: true,
        rpc: {
          137: 'https://polygon-rpc.com',
          42: `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
        }
      },
    ],
  },
  subscriptions: {
    wallet: ({ provider }) => {
      onProvider(provider);
    },
    address: (address) => {
      onAddress(address);
    },
    network: (networkId) => {
      onNetwork(networkId);
    },
  },
  networkId: chainId,
});

export const Web3Context = createContext(null);

const Web3ContextProvider = ({ children, chainId }) => {
  const web3Context = useContext(Web3Context);
  if (web3Context !== null) {
    throw new Error('<Web3ContextProvider /> has already been declared.');
  }

  const [isConnecting, setIsConnecting] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState(null);
  const [connectedProvider, setConnectedProvider] = useState(null);
  const [connectedNetworkId, setConnectedNetworkId] = useState(null);
  const [isEtherspotAccount, setIsEtherspotAccount] = useState(false);
  const [isEmailAdded, setIsEmailAdded] = useState(false);
  const [isAddingEmail, setIsAddingEmail] = useState(false);

  const walletService = useMemo(() => WalletService(
    (address) => {
      if (address) setConnectedAddress(address);
    },
    (provider) => {
      if (provider) setConnectedProvider(new ethers.providers.Web3Provider(provider, chainIdToNetworkName[process.env.REACT_APP_CHAIN_ID]));
    },
    (networkId) => {
      if (networkId) setConnectedNetworkId(networkId);
    },
    chainId,
  ), [chainId]);

  useEffect(() => {
    const savedConnectedAddress = getItem(STORAGE_KEY.CONNECTED_WALLET_ADDRESS);
    if (savedConnectedAddress) setConnectedAddress(savedConnectedAddress);

    const savedWalletProvider = getItem(STORAGE_KEY.CONNECTED_WALLET_PROVIDER);
    if (savedWalletProvider) {
      setIsConnecting(true);
      walletService.walletSelect(savedWalletProvider)
        .then(async () => {
          const isEmailAddedUpdated = await checkIfEmailAdded(savedConnectedAddress);
          setIsEmailAdded(isEmailAddedUpdated);
          setIsConnecting(false);
        })
        .catch(() => {
          setIsConnecting(false);
        });
    }
  }, [walletService]);

  const connect = useCallback(async () => {
    setIsConnecting(true);

    await walletService.walletSelect().catch(() => null);
    await walletService.walletCheck().catch(() => null);

    const { address, wallet: { name } } = walletService.getState();

    if (address) {
      setConnectedAddress(address);
      const isEmailAddedUpdated = await checkIfEmailAdded(address);
      setIsEmailAdded(isEmailAddedUpdated);
      setItem(STORAGE_KEY.CONNECTED_WALLET_ADDRESS, address);
    }

    if (name) {
      setItem(STORAGE_KEY.CONNECTED_WALLET_PROVIDER, name);
    }

    setIsConnecting(false);
  }, [walletService]);

  const reset = useCallback(() => {
    setItem(STORAGE_KEY.CONNECTED_WALLET_ADDRESS, '');
    setItem(STORAGE_KEY.CONNECTED_WALLET_PROVIDER, '');
    walletService.walletReset();
    setConnectedAddress(null);
    setIsEtherspotAccount(false);
  }, [walletService]);

  const updateIsEtherspotAccount = useCallback(async () => {
    if (!connectedAddress) return;

    const state = await checkIfEtherspotAccount(connectedAddress, connectedProvider);
    setIsEtherspotAccount(state);
  }, [connectedAddress, connectedProvider]);

  useEffect(() => {
    updateIsEtherspotAccount();
  }, [updateIsEtherspotAccount]);

  const addEmail = useCallback(async (emailAddress) => {
    if (isAddingEmail) return;
    setIsAddingEmail(true);

    const result = await submitEmail(connectedAddress, emailAddress, connectedProvider);
    if (result?.errorMessage) {
      setIsAddingEmail(false);
      toast.error(result.errorMessage);
      return;
    }

    setIsAddingEmail(false);
    setIsEmailAdded(true);
    toast.success('Successfully added email!');
  },[connectedAddress, connectedProvider, isAddingEmail]);

  const contextData = useMemo(() => ({
    connect,
    reset,
    isConnecting,
    connectedAddress,
    connectedProvider,
    connectedNetworkId,
    chainId,
    isEtherspotAccount,
    isEmailAdded,
    isAddingEmail,
    addEmail,
  }), [
    connect,
    reset,
    isConnecting,
    connectedAddress,
    connectedProvider,
    connectedNetworkId,
    chainId,
    isEtherspotAccount,
    isEmailAdded,
    isAddingEmail,
    addEmail,
  ]);

  return (
    <Web3Context.Provider value={{ data: contextData }}>
      {children}
    </Web3Context.Provider>
  );
};

export default Web3ContextProvider;
