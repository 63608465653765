import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Hero from '../components/Hero';
import { colors, fonts } from '../utils/theme';
import Web3ContextProvider from '../contexts/Web3Context';
import RaffleContextProvider from '../contexts/RaffleContext';

import bgImage from '../assets/bg.jpg';

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Euclid Circular B';
      src: url('fonts/EuclidCircularB/EuclidCircularB-Regular.woff2') format('woff2'),
      url('fonts/EuclidCircularB/EuclidCircularB-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }
    
  @font-face {
      font-family: 'Euclid Circular B';
      src: url('fonts/EuclidCircularB/EuclidCircularB-Bold.woff2') format('woff2'),
            url('fonts/EuclidCircularB/EuclidCircularB-Bold.woff') format('woff');
      font-weight: 600;
      font-style: normal;
  }
    
  @font-face {
      font-family: 'Archia';
      src: url('fonts/Archia/archia-regular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  body {
    background: ${colors.violet};
    background-image: url(${bgImage});
    background-size: cover;
    font-family: ${fonts.primary}, sans;
    color: #fff;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

const HomePage = () => {
  return (
    <>
      <GlobalStyle />
      <Web3ContextProvider>
        <RaffleContextProvider>
          <Hero />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            rtl={false}
            draggable={false}
            hideProgressBar
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </RaffleContextProvider>
      </Web3ContextProvider>
    </>
  )
};

export default HomePage
