import styled from 'styled-components';
import { useState } from 'react';

import useWeb3 from '../hooks/web3';
import { colors, fonts } from '../utils/theme';
import { formatNumber } from '../utils/common';
import useRaffle from '../hooks/raffle';

const Wrapper = styled.div`
  padding: 100px 30px 80px;
  width: 800px;
  margin: 0 auto;
  
  a, a:visited {
    color: ${colors.yellow};
  }
  
  a:hover {
    opacity: 0.5;
  }
  
  @media (max-width: 800px) {
    padding: 30px 20px 80px;
    width: auto;
  }
`;

const SecondaryTitle = styled.h3`
  position: relative;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 3px solid #fff;

  @media (max-width: 700px) {
    font-size: 28px;
    line-height: 38px;
    
    & > br {
      display: none;
    }
  }
`;

const Paragraph = styled.p`
  font-family: ${fonts.secondary};
  font-size: ${({ fontSize }) => fontSize ?? 18}px;
  color: #fff;
  margin-top: ${({ marginTop }) => marginTop ?? 20}px;
  font-weight: 200;
  ${({ center }) => center && `text-align: center;`}
  
  & > strong {
    color: ${colors.yellow};
  }
  
  & > ul {
    padding-left: 20px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

const ParagraphList = styled.ul`
  padding: 0;
  margin: 15px 0 20px 20px;
  font-family: ${fonts.secondary};
  font-size: 18px;
  color: #fff;
  font-weight: 200;

  & > li {
    padding: 0;
    margin: 0 0 5px;
  }
`;

const ConnectedAddressWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 700px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const ConnectedAddress = styled.p`
  font-family: ${fonts.secondary};
  font-size: 15px;
`;

const ActionButton = styled.div`
  display: inline-block;
  background: ${({ disabled, color }) => disabled ? '#737373' : color ?? colors.yellow};
  color: #000;
  padding: 0 25px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  font-family: ${fonts.secondary};
  border-radius: 0.5rem;
  line-height: 50px;
  box-shadow: 1px 2px 34px 12px rgba(0,0,0,0.15);
  cursor: pointer;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}

  ${({ disabled }) => !disabled && `
    &:hover {
      opacity: 0.7;
    }
  `}

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

const DisconnectButton = styled.div`
  color: ${colors.yellow};
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
  
  @media (max-width: 700px) {
    margin: 0;
  }
`;

const InputActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const EmailInput = styled.input`
  width: 300px;
  height: 45px;
  padding: 0 10px;
  font-size: 20px;
  box-shadow: 1px 2px 34px 12px rgba(0,0,0,0.15);
  border: none;
  font-family: ${fonts.secondary};
  margin-right: 20px;

  &::-webkit-inner-spin-button, 
  &::-webkit-outer-spin-button {
    margin-left: 5px;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

const OwnedTicketsWrapper = styled.p`
  margin: 35px 35px 35px 0;
  padding: 25px;
  color: #fff;
  border: 2px solid #fff;
  display: inline-block;
  font-size: 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
`;


const Hero = () => {
  const {
    connect,
    reset,
    isConnecting,
    connectedAddress,
  } = useWeb3();

  const {
    balanceAvailable,
    ownedTickets,
    availableWithdrawAmount,
    isWithdrawing,
    withdrawAvailable,
  } = useRaffle();

  const connectDisabled = !!(isConnecting || connectedAddress);
  const onConnectClick = () => {
    if (connectDisabled) return;
    connect();
  };

  const balanceLoading = balanceAvailable === null;

  const onReceiveClick = () => {
    if (isWithdrawing) return;
    withdrawAvailable();
  };

  return (
    <Wrapper>
      <SecondaryTitle>Pillar Genesis NFT Hodler’s Raffle</SecondaryTitle>
      <Paragraph marginTop={0}>The Pillar Genesis NFT Hodler’s Raffle is exclusively open to Genesis NFT owners who store them in the Pillar Wallet. Simply connect your Pillar Wallet to verify your NFT inside and enter!</Paragraph>
      <ParagraphList marginTop={20}>
        <li>Only 15 $DAI on Polygon Network per entry</li>
        <li>$DAI token rewards to be won by 5 winners</li>
        <li>Maximum addresses that can enter: up to 241!</li>
        <li>Entry will open at 11:00 AM BST 03 May & close 24 May 2022</li>
      </ParagraphList>
      <Paragraph marginTop={20}>Winners will be notified by email. Winners must respond within 3 working days or a new winner will be picked.</Paragraph>
      {!!connectedAddress && (
        <>
          <Paragraph marginTop={40}>Available Balance: {connectedAddress && balanceLoading ? 'Loading...' : <strong>{formatNumber(balanceAvailable)} ${process.env.REACT_APP_RAFFLE_FEE_SYMBOL}</strong>}</Paragraph>
          <InputActionWrapper>
            <ActionButton disabled>Raffle ended!</ActionButton>
          </InputActionWrapper>
          {ownedTickets > 0 && (
            <OwnedTicketsWrapper>
              Owned Ticket(-s): {ownedTickets}
            </OwnedTicketsWrapper>
          )}
          {availableWithdrawAmount > 0 && (
            <ActionButton onClick={onReceiveClick} disabled={isWithdrawing} marginTop={20}>
              {isWithdrawing ? `Receiving...` : `Receive prize of ${formatNumber(availableWithdrawAmount)} $${process.env.REACT_APP_RAFFLE_FEE_SYMBOL}`}
            </ActionButton>
          )}
        </>
      )}
      {!connectedAddress && (
        <ActionButton disabled={connectDisabled} onClick={onConnectClick} marginTop={20}>
          {isConnecting ? `Connecting...` : `Connect wallet`}
        </ActionButton>
      )}
      <Paragraph marginTop={40} fontSize={25}>No Genesis NFT? <a href="https://nftdapp.pillar.fi/" target="_blank" rel="noreferrer">Mint</a></Paragraph>
      <Paragraph marginTop={10}>More info on prizes <a href="https://www.pillar.fi/blog/the-pillar-genesis-nft-crypto-raffle/" target="_blank" rel="noreferrer">here</a></Paragraph>
      <Paragraph marginTop={40}>The prize pool increases the more entries are cast.</Paragraph>
      <Paragraph marginTop={20}>Example:</Paragraph>
      <ParagraphList marginTop={20}>
        <li>Suppose that 241 addresses enter twice each</li>
        <li>$7,230 (DAI) prize pool + Pillar adds $2,000 (DAI) = $9,230 worth of DAI</li>
      </ParagraphList>
      {!!connectedAddress && (
        <ConnectedAddressWrapper>
          <ConnectedAddress>{connectedAddress}</ConnectedAddress>
          <DisconnectButton onClick={reset}>Disconnect</DisconnectButton>
        </ConnectedAddressWrapper>
      )}
    </Wrapper>
  )
};

export default Hero;
