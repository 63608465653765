import axios from 'axios';
import { ethers } from 'ethers';

export const checkIfEmailAdded = async (address) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_EMAIL_SERVICE_HOSTNAME}/check/${address}`);
    return !!data?.isAdded;
  } catch (e) {
    return false;
  }
}

export const submitEmail = async (walletAddress, emailAddress, provider) => {
  if (!emailAddress) {
    return { errorMessage: 'No email address provided!' };
  }

  // eslint-disable-next-line
  if (!new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(emailAddress)) {
    return { errorMessage: 'Invalid email address provided!' };
  }

  const signer = provider.getSigner();
  const messageBytes = ethers.utils.toUtf8Bytes('Pillar Verify Message');

  let signedMessage;
  let signedMessageErrorMessage;
  try {
    signedMessage = await signer.signMessage(messageBytes);
  } catch (e) {
    signedMessageErrorMessage = e?.message;
  }

  if (!signedMessage) {
    return { errorMessage: signedMessageErrorMessage || 'Failed to sign message!' }
  }

  try {
    const { data } = await axios.post(`${process.env.REACT_APP_EMAIL_SERVICE_HOSTNAME}/submit/${walletAddress}`, {
      emailAddress,
      signedMessage,
    });
    return data?.success ? true : { errorMessage: 'Failed to add email, please reach support.' };
  } catch (e) {
    return { errorMessage: e?.message || 'Unknown error!' }
  }
}
