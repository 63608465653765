import { ethers } from 'ethers';

// constants
import { toast } from 'react-toastify';
import {
  isMainnet,
  chainIdToNetworkName,
  chainIdToInfuraPrefix, isCaseInsensitiveMatch,
} from '../utils/common';


const raffleContractAbi = require('../assets/abi/pillarNftRaffle.json');
const feeTokenContractAbi = require('../assets/abi/erc20.json');

export const getDefaultProvider = (chainId = +process.env.REACT_APP_CHAIN_ID) => new ethers.providers.FallbackProvider([
  new ethers.providers.StaticJsonRpcProvider(
    `https://${chainIdToInfuraPrefix[chainId]}.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    chainIdToNetworkName[chainId],
  )
]);

export const getContract = (address, abi, provider = null) => new ethers.Contract(
  address,
  abi,
  provider || getDefaultProvider(),
);

export const getRaffleContract = (provider = null) => getContract(
  process.env.REACT_APP_RAFFLE_CONTRACT_ADDRESS,
  raffleContractAbi,
  provider,
);

export const getFeeTokenContract = (provider = null) => getContract(
  process.env.REACT_APP_ACCEPTED_TOKEN_CONTRACT_ADDRESS,
  feeTokenContractAbi,
  provider,
);

export const approveTokensForRaffle = async (provider) => {
  let result;

  try {
    const signer = provider.getSigner();
    const contract = getFeeTokenContract(signer);
    result = await contract.approve(process.env.REACT_APP_RAFFLE_CONTRACT_ADDRESS, ethers.constants.MaxUint256);
  } catch (e) {
    if (e?.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT && e?.error?.message?.startsWith('execution reverted:')) {
      toast.error(`${e.error.message.replace('execution reverted:', '')}.`);
      return;
    }
  }

  if (!result?.hash) {
    toast.error('Approve failed!');
    return;
  }

  toast.success('Approve transaction sent!', { closeOnClick: false, onClick: () => window.open(getExplorerTransactionLink(result.hash),'_newtab') });

  return result.hash;
}

export const buyTicket = async (connectedAddress, amount, provider) => {
  let result;

  try {
    const nftContrtact = getContract(
      process.env.REACT_APP_PILLAR_NFT_CONTRACT_ADDRESS,
      ['function balanceOf(address account) external view returns (uint256)'],
      provider,
    );
    const nftBalance = await nftContrtact.balanceOf(connectedAddress);
    if (nftBalance.isZero()) {
      toast.error('Must own at least single Pillar NFT!');
      return;
    }

    const signer = provider.getSigner();
    const contract = getRaffleContract(signer);
    const estimatedBN = await contract.estimateGas.buyTicket(amount);
    result = await contract.buyTicket(amount, { gasLimit: estimatedBN.add(estimatedBN.div(10)) });
  } catch (e) {
    if (e?.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT && e?.error?.message?.startsWith('execution reverted:')) {
      toast.error(`${e.error.message.replace('execution reverted:', '')}.`);
      return;
    }
  }

  if (!result?.hash) {
    toast.error('Failed to buy!');
    return;
  }

  toast.success('Buy transaction sent!', { closeOnClick: false, onClick: () => window.open(getExplorerTransactionLink(result.hash),'_newtab') });

  return result.hash;
}

export const withdrawAvailableAmount = async (provider) => {
  let result;

  try {
    const signer = provider.getSigner();
    const contract = getRaffleContract(signer);
    const estimatedBN = await contract.estimateGas.withdrawAvailableAmount();
    result = await contract.withdrawAvailableAmount({ gasLimit: estimatedBN.add(estimatedBN.div(10)) });
  } catch (e) {
    if (e?.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT && e?.error?.message?.startsWith('execution reverted:')) {
      toast.error(`${e.error.message.replace('execution reverted:', '')}.`);
      return;
    }
  }

  if (!result?.hash) {
    toast.error('Failed to receive!');
    return;
  }

  toast.success('Receive transaction sent!', { closeOnClick: false, onClick: () => window.open(getExplorerTransactionLink(result.hash),'_newtab') });

  return result.hash;
}

export const getFeeTokenBalanceForAddress = async (address, provider) => {
  let balance = 0;

  try {
    const contract = getFeeTokenContract(provider);
    const balanceBN = await contract.balanceOf(address);
    return +ethers.utils.formatUnits(balanceBN, 18);
  } catch (e) {
    //
  }

  return balance;
}

export const checkIfApprovedForBuying = async (connectedAddress, provider) => {
  try {
    const contract = getFeeTokenContract(provider);
    const approvedAmountBN = await contract.allowance(connectedAddress, process.env.REACT_APP_RAFFLE_CONTRACT_ADDRESS);
    return approvedAmountBN.gte(ethers.constants.MaxUint256);
  } catch (e) {
    //
  }

  return false;
}

export const getOwnedTicketCount = async (connectedAddress, provider) => {
  try {
    const contract = getRaffleContract(provider);
    const participants = await contract.getParticipants();
    return participants.filter((participant) => isCaseInsensitiveMatch(participant, connectedAddress)).length;
  } catch (e) {
    //
  }

  return 0;
}

export const getAvailableWithdrawAmount = async (connectedAddress, provider) => {
  try {
    const contract = getRaffleContract(provider);
    const amountBN = await contract.getAvailableWithdrawAmount(connectedAddress);
    return +ethers.utils.formatUnits(amountBN, 18);
  } catch (e) {
    //
  }

  return 0;
}

export const checkIfEtherspotAccount = async (connectedAddress, provider) => {
  try {
    const contract = getContract(
      process.env.REACT_APP_ETHERSPOT_ACCOUNT_REGISTRY_CONTRACT_ADDRESS,
      ['function isAccountDeployed(address account) external view returns (bool)'],
      provider,
    );
    return contract.isAccountDeployed(connectedAddress);
  } catch (e) {
    //
  }

  return false;
}

export const waitForTransaction = async (hash) => {
  try {
    return getDefaultProvider().waitForTransaction(hash);
  } catch (e) {
    //
  }

  return null;
}

export const getExplorerLink = () => isMainnet ? `https://polygonscan.com` : `https://kovan.etherscan.io`;

export const getExplorerTransactionLink = (hash) => `${getExplorerLink()}/tx/${hash}`;
