import { useContext, useMemo, createContext, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

// hooks
import useWeb3 from '../hooks/web3';

// services
import {
  approveTokensForRaffle,
  checkIfApprovedForBuying,
  getFeeTokenBalanceForAddress,
  buyTicket,
  getOwnedTicketCount,
  waitForTransaction, getAvailableWithdrawAmount, withdrawAvailableAmount,
} from '../services/contract';
import { ethers } from 'ethers';

export const RaffleContext = createContext(null);

const raffleFeeBN = ethers.utils.parseEther(process.env.REACT_APP_RAFFLE_FEE);

const RaffleContextProvider = ({ children }) => {
  const raffleContext = useContext(RaffleContext);
  if (raffleContext !== null) {
    throw new Error('<RaffleContextProvider /> has already been declared.');
  }

  const { connectedProvider, connectedAddress } = useWeb3();
  const [isBuying, setIsBuying] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isApprovedForBuying, setIsApprovedForBuying] = useState(false);
  const [balanceAvailable, setBalanceAvailable] = useState(null);
  const [ownedTickets, setOwnedTickets] = useState(0);
  const [availableWithdrawAmount, setAvailableWithdrawAmount] = useState(0);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const buy = useCallback(async (amount) => {
    setIsBuying(true);
    if (isBuying) return;

    const hash = await buyTicket(connectedAddress, amount, connectedProvider);
    if (!hash) {
      setIsBuying(false);
      return;
    }

    const receipt = await waitForTransaction(hash);
    if (receipt?.status === 0) {
      toast.error('Buy failed!');
      setIsBuying(false);
      return;
    }

    setIsBuying(false);
    setBalanceAvailable((current) => +current - +ethers.utils.formatEther(raffleFeeBN.mul(amount)));
    setOwnedTickets((owned) => owned + amount);
    toast.success('Successfully bought!');
  },[connectedAddress, connectedProvider, isBuying]);

  const withdrawAvailable = useCallback(async () => {
    setIsWithdrawing(true);
    if (isWithdrawing) return;

    const hash = await withdrawAvailableAmount(connectedProvider);
    if (!hash) {
      setIsWithdrawing(false);
      return;
    }

    const receipt = await waitForTransaction(hash);
    if (receipt?.status === 0) {
      toast.error('Receive failed!');
      setIsWithdrawing(false);
      return;
    }

    setIsWithdrawing(false);
    setBalanceAvailable((current) => +current + +availableWithdrawAmount);
    setAvailableWithdrawAmount(0);
    toast.success('Successfully received!');
  },[connectedProvider, isWithdrawing, availableWithdrawAmount]);

  const updateApprovedForBuying = useCallback(async () => {
    if (!connectedAddress) return;

    const isApproved = await checkIfApprovedForBuying(connectedAddress, connectedProvider);
    setIsApprovedForBuying(isApproved);
  },[connectedAddress, connectedProvider]);

  useEffect(() => {
    updateApprovedForBuying();
  }, [updateApprovedForBuying]);

  const approveForBuying = useCallback(async () => {
    setIsApproving(true);
    if (isApproving) return;

    const hash = await approveTokensForRaffle(connectedProvider);
    if (!hash) {
      setIsApproving(false);
      return;
    }

    const receipt = await waitForTransaction(hash);
    if (receipt?.status === 0) {
      toast.error('Approving failed!');
      setIsApproving(false);
      return;
    }

    setIsApproving(false);
    setIsApprovedForBuying(true);
    toast.success('Successfully approved!');
  },[connectedProvider, isApproving]);

  const updateBalanceAvailable = useCallback(async () => {
    if (!connectedAddress) return;

    const currentBalance = await getFeeTokenBalanceForAddress(connectedAddress, connectedProvider);
    setBalanceAvailable(currentBalance);
  }, [connectedAddress, connectedProvider]);

  useEffect(() => {
    updateBalanceAvailable();
  }, [updateBalanceAvailable]);

  const updateAvailableWithdrawAmount = useCallback(async () => {
    if (!connectedAddress) return;

    const available = await getAvailableWithdrawAmount(connectedAddress, connectedProvider);
    setAvailableWithdrawAmount(available);
  }, [connectedAddress, connectedProvider]);

  useEffect(() => {
    updateAvailableWithdrawAmount();
  }, [updateAvailableWithdrawAmount]);

  const checkIfOwnedTickets = useCallback(async () => {
    if (!connectedAddress) return;

    const count = await getOwnedTicketCount(connectedAddress, connectedProvider);
    setOwnedTickets(count);
  }, [connectedAddress, connectedProvider]);

  useEffect(() => {
    checkIfOwnedTickets();
  }, [checkIfOwnedTickets]);

  const contextData = useMemo(() => ({
    buy,
    isBuying,
    balanceAvailable,
    isApprovedForBuying,
    isApproving,
    approveForBuying,
    ownedTickets,
    availableWithdrawAmount,
    withdrawAvailable,
    isWithdrawing,
    fee: +ethers.utils.formatEther(raffleFeeBN).toString(),
  }), [
    buy,
    isBuying,
    balanceAvailable,
    isApprovedForBuying,
    isApproving,
    approveForBuying,
    ownedTickets,
    availableWithdrawAmount,
    withdrawAvailable,
    isWithdrawing,
  ]);

  return (
    <RaffleContext.Provider value={{ data: contextData }}>
      {children}
    </RaffleContext.Provider>
  );
};

export default RaffleContextProvider;
